import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {PencilIcon} from '@common/auth/ui/ito/icons/pencil-icon';
import {TrashIcon} from '@common/auth/ui/ito/icons/trash-icon';
import {Link} from 'react-router-dom';
import {useServices} from '@common/ito/composables/useServices';
import StatusBadge from '@common/auth/ui/ito/components/status-badge';
import Badge from '@common/auth/ui/ito/components/badge';
import routeNames from '@common/ito/route-names';
import Pagination from '@common/auth/ui/ito/pagination';
import TableH from '@common/auth/ui/ito/components/table/TableH';
import TableD from '@common/auth/ui/ito/components/table/TableD';
import Table from '@common/auth/ui/ito/components/table/Table';
import TableHead from '@common/auth/ui/ito/components/table/TableHead';
import TableBody from '@common/auth/ui/ito/components/table/TableBody';
import TableTr from '@common/auth/ui/ito/components/table/TableTr';
import {TailwindDialog} from '@common/auth/ui/ito/components/TailwindDialog';
import React from 'react';
import {Trans} from '@common/i18n/trans';

interface ServicesTableProps {}

export function ServiceTable({ }: ServicesTableProps) {
  const {
    deleteDialogOpened,
    services,
    currentPage,
    totalPages,
    isLoading,
    error,
    setPage,
    openDeleteDialog,
    handleDeleteDialogConfirm,
    handleDeleteDialogClose,
  } = useServices(10);

  return (
    <>
      <TailwindDialog
        isOpen={deleteDialogOpened}
        title="Confrim Service Deletion"
        message="Are you sure? You want to delete?"
        confirmText="Yes, Delete it"
        onConfirm={handleDeleteDialogConfirm}
        onClose={handleDeleteDialogClose}
      />
      <Table>
        <TableHead>
          <tr>
            <TableH text="Product Name" />
            <TableH text="Service Type" />
            <TableH text="Status" />
            <TableH text="Brand" />
            <TableH text="Price" />
            {/*<TableH text="Duration" />*/}
            {/*<TableH text="Last Activity Date" />*/}
            {/*<TableH text="Balance" />*/}
            {/*<TableH text="Pending Payments" />*/}
            {/*<TableH text="Next Scheduled Payment" />*/}
            {/*<TableH text="Next Payment Amount" />*/}
            <TableH text="Added At" />
            <TableH text="Action" center={true} />
          </tr>
        </TableHead>
        <TableBody>
          {error && (
            <TableTr>
              <TableD colSpan={7} text={error} className="relative h-32" />
            </TableTr>
          )}
          {isLoading ? (
            <TableTr>
              <TableD colSpan={7} className="relative h-32">
                <ProgressCircle
                  className="my-80"
                  size="sm"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    margin: '0',
                  }}
                  aria-label="Loading services..."
                  isIndeterminate
                />
              </TableD>
            </TableTr>
          ) : !services.length ? (
            <TableTr>
              <TableD center={true} colSpan={7}>
                <Trans message="There is no service!" />
              </TableD>
            </TableTr>
          ) : (
            services.map(service => (
              <TableTr key={`service-row-${service.id}`}>
                <TableD text={service?.name ?? ''} noWrap />
                <TableD text={service?.service_type ?? ''} />
                <TableD>
                  <StatusBadge status={!!service.status} />
                </TableD>
                <TableD text={service?.reskillify_brand_name?.toString() ?? ''} noWrap />
                <TableD text={(service?.currency?.symbol ?? '$') + service?.amount ?? ''} />
                {/*<TableD text={service?.duration ?? ''} />*/}
                <TableD text={service?.created_at ?? ''} />
                {/*<TableD>*/}
                {/*  <Badge dot={false}>N/A</Badge>*/}
                {/*</TableD>*/}
                {/*<TableD>*/}
                {/*  <Badge dot={false}>N/A</Badge>*/}
                {/*</TableD>*/}
                {/*<TableD>*/}
                {/*  <Badge dot={false}>N/A</Badge>*/}
                {/*</TableD>*/}
                {/*<TableD>*/}
                {/*  <Badge dot={false}>N/A</Badge>*/}
                {/*</TableD>*/}
                <TableD>
                  <div className="flex justify-center space-x-2">
                    <Link
                      to={routeNames.payments.services.edit(
                        service.id as number,
                      )}
                      className="text-blue-400 hover:text-blue-600 h-5 w-5"
                    >
                      <PencilIcon />
                    </Link>
                    <button
                      className="text-red-500 hover:text-red-700 h-5 w-5"
                      onClick={() => openDeleteDialog(service.id as number)}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </TableD>
              </TableTr>
            ))
          )}
        </TableBody>
      </Table>
      {(services?.length ?? 0) > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setPage}
        />
      )}
    </>
  );
}
