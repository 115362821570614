import {
  useUploadAvatar
} from '@common/auth/ui/account-settings/avatar/upload-avatar';
import {
  useRemoveAvatar
} from '@common/auth/ui/account-settings/avatar/remove-avatar';
import React, {ChangeEvent, useId, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Form} from '@common/ui/forms/form';
import {User} from '@common/auth/user';
import {
  useUpdateAccountDetails
} from '@common/auth/ui/account-settings/basic-info-panel/update-account-details';
import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';
import {useEffect} from 'react';

interface Props {
  user: User;
}
interface SubmitType {
  first_name: string;
  last_name: string;
  avatar: string;
}

export function ITO_BasicInfoPanel({user}: Props) {
  const uploadAvatar = useUploadAvatar({user});
  const removeAvatar = useRemoveAvatar({user});
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const formId = useId();
  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      avatar: user.avatar,
    },
  });
  const updateDetails = useUpdateAccountDetails(form);
  const placeholderImagePath = 'https://www.gravatar.com/avatar/0abf4405f8ac86244acccffe228a8565?s=&d=retro'

  useEffect(() => {
    const avatar = form.getValues('avatar') || user.avatar

    if (avatar) {
      setImageUrl(avatar)
    }
  }, [])

  const updateImage = (file: File) => {
    if (file) {
      uploadAvatar.mutate({
        // @ts-ignore
        file: {
          native: file
        }
      });
      const reader = new FileReader();
      reader.onload = () => {
        const fileResult = reader.result as string
        setImageUrl(fileResult);
      };
      reader.readAsDataURL(file);
    }
  }

  const onAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files.length > 0) {
      updateImage(event.target.files[0])
    } else {
      removeAvatar.mutate();
    }
  }

  const onAvatarRemove = () => {
    removeAvatar.mutate()
    form.setValue('avatar', '')
    setImageUrl(null)
  }

  const onSubmit = (data: SubmitType) => {
    updateDetails.mutate(data);
  }

  return (
    <div
      id={AccountSettingsId.AccountDetails}
      className="border-gray-300 mb-6 rounded-lg border-b bg-white p-6 shadow"
    >
      {/* @ts-ignore */}
      <Form form={form} id={formId} onSubmit={onSubmit}>
        <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
          <Trans message="Update name and profile image" />
        </h2>
        <div className="flex items-start gap-6">
          <div className="flex-1">
            <div className="mb-4">
              <label className="mb-1.5 block text-sm font-medium">
                <Trans message="First name" />
              </label>
              <input
                {...form.register('first_name')}
                name="first_name"
                type="text"
                placeholder="Dee"
                className="border-gray-300 px-2.5 py-1.5 w-full rounded border"
              />
              {form?.formState?.errors?.first_name && (
                <span className="text-red-500">
                  {form?.formState?.errors?.first_name?.message}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label className="mb-1.5 block text-sm font-medium">
                <Trans message="Last Name" />
              </label>
              <input
                {...form.register('last_name')}
                name="last_name"
                type="text"
                placeholder="Dee"
                className="border-gray-300 px-2.5 py-1.5 w-full rounded border"
              />
              {form?.formState?.errors?.last_name && (
                <span className="text-red-500">
                  {form?.formState?.errors?.last_name?.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex-shrink-0 text-center">
            <p className="mb-2 text-sm font-semibold">
              <Trans message="Profile Image" />
            </p>
            <input
              {...form.register('avatar')}
              name="avatar"
              id="avatar"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={onAvatarChange}
            />
            <label htmlFor="avatar" className="relative block h-24 w-24">
              <div className="overflow-hidden rounded-full">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    className="h-24 w-full object-cover"
                    alt=""
                  />
                ) : (
                  <img
                    src={placeholderImagePath}
                    alt="Profile Placeholder"
                    className="h-24 w-full object-cover"
                  />
                )}
              </div>
              <div className="h-7 w-7 absolute bottom-0 right-0 m-1 flex items-center justify-center rounded-full bg-white">
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2868 3.08706L12.2085 1.3834C11.9813 1.11849 11.6698 0.953228 11.3206 0.953228H7.59411C7.24496 0.953228 6.93347 1.11849 6.70623 1.3834L5.62798 3.08706C5.40074 3.35237 5.10789 3.54558 4.75873 3.54558H2.3284C1.98463 3.54558 1.65495 3.68214 1.41187 3.92522C1.16879 4.1683 1.03223 4.49799 1.03223 4.84175V12.6188C1.03223 12.9626 1.16879 13.2923 1.41187 13.5353C1.65495 13.7784 1.98463 13.915 2.3284 13.915H16.5863C16.9301 13.915 17.2598 13.7784 17.5029 13.5353C17.7459 13.2923 17.8825 12.9626 17.8825 12.6188V4.84175C17.8825 4.49799 17.7459 4.1683 17.5029 3.92522C17.2598 3.68214 16.9301 3.54558 16.5863 3.54558H14.1965C13.8461 3.54558 13.514 3.35237 13.2868 3.08706Z"
                    stroke="#2C3E50"
                    strokeWidth="1.29618"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.32784 11.4198C11.1175 11.4198 12.5683 9.93277 12.5683 8.09839C12.5683 6.264 11.1175 4.77694 9.32784 4.77694C7.5382 4.77694 6.0874 6.264 6.0874 8.09839C6.0874 9.93277 7.5382 11.4198 9.32784 11.4198Z"
                    stroke="#2C3E50"
                    strokeWidth="1.29618"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M3.98092 3.36533V2.45193H3.00879V3.36533"
                    stroke="#2C3E50"
                    strokeWidth="1.29618"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </label>
            {imageUrl !== placeholderImagePath && imageUrl && (
              <button
                onClick={onAvatarRemove}
                className="text-red-500 mt-2 text-sm"
              >
                <Trans message="Remove Image" />
              </button>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="bg-purple-800 hover:bg-purple-800 mt-4 rounded-lg px-10 py-2 text-sm text-white"
        >
          <Trans message="Save" />
        </button>
      </Form>
    </div>
  );
}
