import {useEffect, useRef, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import useCurrencies from '@common/ito/composables/useCurrencies';
import usePaymentMethods from '@common/ito/composables/usePaymentMethods';
import useBrands from '@common/ito/composables/useBrands';
import {PaymentGatewayFormValues} from '@common/auth/ui/ito/payments/gateway/edit/update-payment-gateway';
import {
  Gateway,
  PaymentGatewayMode,
  usePaymentGateway,
} from '@common/ito/composables/usePaymentGateway';
import {usePaymentGatewayAction} from '@common/auth/ui/ito/payments/gateway/use-payment-gateway-action';
import {useAuth} from '@common/auth/use-auth';

interface UsePaymentGatewayFormProps {
  paymentGatewayId?: number | string;
  paymentGatewayName?: string;
}

export default function usePaymentGatewayForm ({
  paymentGatewayId = '',
  paymentGatewayName = '',
}: UsePaymentGatewayFormProps) {
  const {user} = useAuth()
  const {gateways, gateway, get, show} = usePaymentGateway();
  const {currencies, getCurrencies} = useCurrencies();
  const {paymentMethods, getPaymentMethods} = usePaymentMethods();
  const {brands, getBrands} = useBrands();
  const [isLoading, setIsLoading] = useState(false)
  const initialLoad = useRef(true);
  const currentGatewayKeys = getPaymentGatewayKey(gateway as Gateway, gateway?.mode)
  const form = useForm<PaymentGatewayFormValues>({
    defaultValues: {
      name: paymentGatewayName ? paymentGatewayName : '',
      title: '',
      mode: 'sandbox',
      public_key: '',
      secret_key: '',
      client_id: '',
      admin_email: '',
      status: 1,
      billing_country: 'USA',
      default_payment_gateway_id: '',
      payment_method_ids: [],
      reskillify_brand_id: '',
      reskillify_brand_name: '',
      currency_id: 0,
      currency_symbol_position: '', //CurrencySymbolPosition.left,
      currency: {},
    },
  });
  const paymentGatewayAction = usePaymentGatewayAction({
    form,
    paymentGatewayId,
  });
  const currencyId = useWatch({
    control: form.control,
    name: 'currency_id'
  })

  useWatch({
    control: form.control,
    name: 'status',
  })
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      fetchData()
    }
  }, []);
  useEffect(() => {
    const defaultPaymentGateway = gateways.find(gateway => !!gateway.is_default);

    if (defaultPaymentGateway && defaultPaymentGateway.id) {
      form.setValue('default_payment_gateway_id', defaultPaymentGateway.id)
    }
  }, [gateways]);
  useEffect(() => {
    const selectedCurrency = currencies.find(
      (currency) => currency.id == currencyId
    );

    if (selectedCurrency && selectedCurrency.symbol_position) {
      form.setValue('currency_symbol_position', selectedCurrency.symbol_position);
    }
  }, [currencyId]);
  useEffect(() => {
    // Fill currency
    const usdCurrency = currencies.find((currency) => currency?.code?.toLowerCase() === 'usd')
    form.setValue('currency_id', usdCurrency?.id ?? '')
    form.setValue('currency_symbol_position', usdCurrency?.symbol_position ?? '')
  }, [currencies]);
  useEffect(() => {
    if (!gateway?.id) {
      return
    }

    resetForm()
  }, [gateway]);

  function currentCurrency() {
    if (currencies.length > 0) {
      return currencies.find((currency) => currency.id == form.getValues('currency_id'));
    }

    return null;
  }

  function cleanFormData(data: PaymentGatewayFormValues) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        // If the value is an array, remove empty elements
        if (Array.isArray(value)) {
          return [key, value.filter(item => item !== '')];
        }
        return [key, value]; // Keep other values as they are
      })
    );
  }

  function onModeChanged(incomingMode: boolean) {
    form.setValue('mode', !incomingMode ? 'live' : 'sandbox');
  }

  function getPaymentGatewayKey(gateway: Gateway, mode?: string) {
    const emptyKeys = {
      public_key: '',
      secret_key: '',
    };

    if (!mode) {
      return emptyKeys;
    }

    if (mode == PaymentGatewayMode.live) {
      return gateway?.payment_gateway_keys?.find((key) => key.type == PaymentGatewayMode.live) ?? emptyKeys;
    }

    return gateway?.payment_gateway_keys?.find((key) => key.type == PaymentGatewayMode.sandbox) ?? emptyKeys;
  }

  function resetForm() {
    const defaultGatewayId = gateways.find(gateway => !!gateway.is_default)?.id ?? ''
    form.reset({
      ...form.getValues(),
      name: gateway?.name ?? '',
      title: gateway?.title ?? '',
      mode: (gateway?.mode ?? 'sandbox'),
      public_key: currentGatewayKeys?.public_key ?? '',
      secret_key: currentGatewayKeys?.secret_key ?? '',
      client_id: gateway?.client_id ?? '',
      admin_email: gateway?.admin_email ?? user?.email,
      status: gateway?.status !== 'inactive',
      billing_country: gateway?.billing_country ?? '',
      default_payment_gateway_id: defaultGatewayId,
      payment_method_ids: gateway?.payment_method_ids ?? [],
      brand_id: gateway?.brand_id ?? '',
      reskillify_brand_id: gateway?.reskillify_brand_id ?? '',
      reskillify_brand_name: gateway?.reskillify_brand_name ?? '',
      currency_id: gateway?.currency_id ?? 0,
      currency: gateway?.currency ?? {},
      currency_symbol_position: gateway?.currency?.symbol_position ?? '',
    });
  }

  async function fetchData () {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true)
        await getCurrencies();
        await getPaymentMethods();
        await getBrands();
        await get();

        if (paymentGatewayId) {
          await show(paymentGatewayId);
        }

        resolve(true);
      } catch (error) {
        reject(error);
      } finally {
        setIsLoading(false)
      }
    })
  }

  function onSubmit(data: PaymentGatewayFormValues) {
    setIsLoading(true)

    const cleanedData = cleanFormData(data) as PaymentGatewayFormValues
    cleanedData['reskillify_brand_name'] =
      brands?.find(brand => brand.id == cleanedData['reskillify_brand_id'])
        ?.name ?? '';

    paymentGatewayAction?.mutate(cleanedData)
    setIsLoading(false)
  }

  return {
    user,
    isLoading,
    gateways,
    form,
    currencies,
    paymentMethods,
    brands,
    setIsLoading,
    onSubmit,
    onModeChanged,
    currentCurrency,
  }
}
