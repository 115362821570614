import {Trans} from '@common/i18n/trans';
import React, {ReactNode} from 'react';

export interface TableDataProps {
  text?: string;
  className?: string;
  center?: boolean;
  children?: ReactNode;
  noWrap?: boolean;
  colSpan?: number;
}

export default function TableH ({
  text = '',
  children = null,
  className = '',
  center = false,
  noWrap = false,
  colSpan = 1,
}: TableDataProps) {

  if (!text && !children) {
    throw new Error('TableH must have either text or children');
  }

  return (
    <th
      className={
        'text-gray-500 whitespace-nowrap px-4 py-3 text-left font-medium '
        + className
        + (center && ' text-center ')
        + (noWrap && ' whitespace-nowrap ')
      }
      colSpan={colSpan}
    >
      {text ? (
        <Trans message={text} />
      ) : (
        <>{children}</>
      )}
    </th>
  );
}
