import React from 'react';
import AccountSettingSidebarLink
  from '@common/auth/ui/account-settings/account-setting-sidebar-link';
import AccountSettingSidebarDropdownLink
  from '@common/auth/ui/account-settings/account-setting-sidebar-dropdown-link';
import UserIcon from '@common/ui/layout/ito/sidebar/user-icon';
import LoginIcon from '@common/ui/layout/ito/sidebar/login-icon';
import LockIcon from '@common/ui/layout/ito/sidebar/lock-icon';
import ShieldIcon from '@common/ui/layout/ito/sidebar/shield-icon';
import ScreenTickIcon from '@common/ui/layout/ito/sidebar/screen-tick-icon';
import MapIcon from '@common/ui/layout/ito/sidebar/map-icon';
import DollarIcon from '@common/ui/layout/ito/sidebar/doller-icon';
import AngleIcon from '@common/ui/layout/ito/sidebar/angle-icon';
import DashboardIcon from '@common/ui/layout/ito/sidebar/dashboard-icon';
import SuitcaseIcon from '@common/ui/layout/ito/sidebar/suitcase-icon';
import TodosIcon from '@common/ui/layout/ito/sidebar/todos-icon';
import GearsIcon from '@common/ui/layout/ito/sidebar/gears-icon';
import MobileTickMarkIcon from '@common/ui/layout/ito/sidebar/mobile-tick-mark-icon';
import GearIcon from '@common/ui/layout/ito/sidebar/gear-icon';
import CrossCircleIcon from '@common/ui/layout/ito/sidebar/cross-circle-icon';
import useSidebar from '@common/ui/layout/ito/sidebar/sidebar';
import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';
import routeNames from '@common/ito/route-names';

interface Props {
  onToggleSidebar: () => void;
}

export function ITO_SidebarComponent({ onToggleSidebar }: Props) {
  const ids = AccountSettingsId
  const {
    isPaymentsOpen,
    isPaymentsRelated,
    togglePayments,
    closePaymentsDropdown,
  } = useSidebar()

  return (
    <aside
      className={'dashboard-sidebar w-full h-screen overflow-y-auto sticky top-0 text-gray-800 bg-white p-4 shadow-lg md:shadow-none'}
    >
      <div className="relative mb-4 flex items-center justify-center">
        <img src="/images/logo-s.png" alt="Logo" />
        <button
          className='md:hidden absolute right-0 text-3xl font-extralight hover:text-red-500'
          onClick={onToggleSidebar}>&times;</button>
      </div>
      <nav className="space-y-2">
        <AccountSettingSidebarLink
          onClick={closePaymentsDropdown}
          goto={ids.AccountDetails}
          to={routeNames.payments.settings.listing}
          title="Account Details"
          Icon={
            <UserIcon />
          }
        />
        {/*<AccountSettingSidebarLink*/}
        {/*  onClick={closePaymentsDropdown}*/}
        {/*  goto={ids.AccountDetails}*/}
        {/*  to={routeNames.payments.settings.listing}*/}
        {/*  title="Social Login"*/}
        {/*  Icon={*/}
        {/*    <LoginIcon />*/}
        {/*  }*/}
        {/*/>*/}
        <AccountSettingSidebarLink
          onClick={closePaymentsDropdown}
          goto={ids.Password}
          to={routeNames.payments.settings.listing}
          title="Password"
          Icon={
            <LockIcon />
          }
        />
        {/*<AccountSettingSidebarLink*/}
        {/*  onClick={closePaymentsDropdown}*/}
        {/*  goto={ids.TwoFactor}*/}
        {/*  to={routeNames.payments.settings.listing}*/}
        {/*  title="Two-factor authentication"*/}
        {/*  Icon={*/}
        {/*    <ShieldIcon />*/}
        {/*  }*/}
        {/*/>*/}
        {/*<AccountSettingSidebarLink*/}
        {/*  onClick={closePaymentsDropdown}*/}
        {/*  goto={ids.Sessions}*/}
        {/*  to={routeNames.payments.settings.listing}*/}
        {/*  title="Active Sessions"*/}
        {/*  Icon={*/}
        {/*    <ScreenTickIcon />*/}
        {/*  }*/}
        {/*/>*/}
        {/*<AccountSettingSidebarLink*/}
        {/*  onClick={closePaymentsDropdown}*/}
        {/*  goto={ids.LocationAndLanguage}*/}
        {/*  to={routeNames.payments.settings.listing}*/}
        {/*  title="Location and Language"*/}
        {/*  Icon={*/}
        {/*    <MapIcon />*/}
        {/*  }*/}
        {/*/>*/}
        <button
          onClick={togglePayments}
          className={`flex w-full items-center justify-between px-4 py-3 text-sm text-left ${
            isPaymentsOpen || isPaymentsRelated ? 'bg-purple-800 text-white' : ''
          } hover:bg-purple-800 rounded-lg hover:text-white`}
        >
          <span className="flex items-center">
            <span className="mr-2">
              <DollarIcon />
            </span>
            <Trans message='Payments' />
          </span>
          <span>
            <AngleIcon down={isPaymentsOpen} />
          </span>
        </button>
        {(isPaymentsOpen) && (
          <div className="space-y-1 pl-6">
            {/*
              TODO!
              un comment the following line
            */}
            {/*<AccountSettingSidebarDropdownLink*/}
            {/*  to={routeNames.payments.dashboard.listing}*/}
            {/*  title="Payment Dashboard"*/}
            {/*  Icon={*/}
            {/*    <DashboardIcon />*/}
            {/*  }*/}
            {/*/>*/}
            <AccountSettingSidebarDropdownLink
              to={routeNames.payments.gateway.listing}
              title="Payment Gateway"
              Icon={
                <SuitcaseIcon />
              }
            />
            {/*
              TODO!
              un comment the following line
            */}
            {/*<AccountSettingSidebarDropdownLink*/}
            {/*  to={routeNames.payments.profile.listing}*/}
            {/*  title="Payment Profile"*/}
            {/*  Icon={*/}
            {/*    <TodosIcon />*/}
            {/*  }*/}
            {/*/>*/}
            <AccountSettingSidebarDropdownLink
              to={routeNames.payments.services.listing}
              title="Products & Services"
              Icon={
                <GearsIcon />
              }
            />
            {/*
              TODO!
              un comment the following line
            */}
            {/*<AccountSettingSidebarDropdownLink*/}
            {/*  to={routeNames.payments.methods.listing}*/}
            {/*  title="Payment Methods"*/}
            {/*  Icon={*/}
            {/*    <MobileTickMarkIcon />*/}
            {/*  }*/}
            {/*/>*/}
            <AccountSettingSidebarDropdownLink
              to={routeNames.payments.orders.listing}
              title="Payment Activity"
              Icon={
                <TodosIcon />
              }
            />
            {/*
              TODO!
              un comment the following line
            */}
            {/*<AccountSettingSidebarDropdownLink*/}
            {/*  to={routeNames.payments.settings.listing}*/}
            {/*  title="Payment Settings"*/}
            {/*  Icon={*/}
            {/*    <GearIcon />*/}
            {/*  }*/}
            {/*/>*/}
          </div>
        )}
        <AccountSettingSidebarLink
          onClick={closePaymentsDropdown}
          goto={ids.DeleteAccount}
          to={routeNames.payments.settings.listing}
          title="Delete Account"
          Icon={
            <CrossCircleIcon />
          }
        />
      </nav>
    </aside>
  );
}
