import {useEffect, useRef, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import useCurrencies from '@common/ito/composables/useCurrencies';
import useBrands from '@common/ito/composables/useBrands';
import {ServiceFormValues}
  from '@common/auth/ui/ito/payments/services/edit/update-service';
import {Service, useServices} from '@common/ito/composables/useServices';
import {
  useServiceAction
} from '@common/auth/ui/ito/payments/services/use-service-action';

interface UseServiceFormProps {
  serviceId?: number | string;
}

export default function useServiceForm ({serviceId = ''}: UseServiceFormProps) {
  const {service, get, show} = useServices();
  const [selectedRecurring, setSelectedRecurring] = useState(0);
  const [addonSelected, setAddonSelected] = useState<string[]>(['Upsell']);
  const {currencies, getCurrencies} = useCurrencies();
  const {brands, getBrands} = useBrands();
  const [isLoading, setIsLoading] = useState(false)
  const initialLoad = useRef(true);
  const form = useForm<ServiceFormValues>({
    defaultValues: {
      reskillify_brand_id: '',
      reskillify_brand_name: '',
      currency_id: '',
      name: '',
      service_type: '',
      descriptions: '',
      statement_descriptions: '',
      sale_price: '',
      recurring: 0,
      upsell: 0,
      bump: 0,
      one_time_order: 0,
      affiliate_commission: 0,
      image: '',
      status: 1,
    },
  });
  const serviceTypes = [
    {id: '1', name: 'Course'},
    {id: '2', name: 'Clinic'},
    {id: '3', name: 'Digital Product'},
    {id: '4', name: 'Community Membership', isDisabled: true},
    {id: '5', name: 'Bootcamp'},
  ];
  const serviceAction = useServiceAction({
    form,
    serviceId,
  });
  useWatch({
    control: form.control,
    name: 'currency_id'
  })

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      fetchData()
    }
  }, []);
  useEffect(() => {
    if (!service?.id) {
      return
    }

    resetForm()
  }, [service]);
  useEffect(() => {
    if (!service?.id && currencies.length) {
      const usdCurrency = getUSDCurrency();
      form.setValue('currency_id', usdCurrency?.id ?? '')
    }
  }, [currencies, service?.id]);

  function currentCurrency() {
    if (currencies.length > 0) {
      return currencies.find((currency) => currency.id == form.getValues('currency_id'));
    }

    return null;
  }

  function cleanFormData(data: ServiceFormValues) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        // If the value is an array, remove empty elements
        if (Array.isArray(value)) {
          return [key, value.filter(item => item !== '')];
        }
        return [key, value]; // Keep other values as they are
      })
    );
  }


  function resetForm() {
    const currentServiceType = serviceTypes.find(currentService => (currentService.name == service?.service_type ?? ''));
    const usdCurrency = getUSDCurrency();

    form.reset({
      ...form.getValues(),

      brand_id: service?.brand_id ?? '',
      reskillify_brand_id: service?.reskillify_brand_id ?? '',
      reskillify_brand_name: service?.reskillify_brand_name ?? '',
      currency_id: service?.currency_id ?? usdCurrency?.id ?? '',
      status: service?.status ?? 1,

      name: service?.name ?? '',
      service_type: currentServiceType?.id?.toString() ?? '',
      descriptions: service?.descriptions ?? '',
      statement_descriptions: service?.statement_descriptions ?? '',
      duration: service?.duration ?? '',
      product_id_on_reskillify: service?.product_id_on_reskillify ?? '',
      pricing_model: service?.pricing_model ?? '',
      recurring: service?.recurring ?? 0,
      quantity: service?.quantity ?? '',
      sku: service?.sku ?? '',
      amount: service?.amount ?? '',
      sale_price: service?.sale?.sale_price ?? '',
      purchase_note: service?.purchase_note ?? '',
      type: service?.type ?? '',
      upsell: service?.upsell ?? 0,
      bump: service?.bump ?? 0,
      one_time_order: service?.one_time_order ?? 0,
      affiliate_commission: service?.affiliate_commission ?? 0,
      image: service?.image ?? '',
    });
  }

  function getUSDCurrency() {
    return currencies.find((currency) => currency?.code?.toLowerCase() === 'usd')
  }

  async function fetchData () {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true)
        await getCurrencies();
        await getBrands();
        await get();

        if (serviceId) {
          await show(serviceId);
        }

        resolve(true);
      } catch (error) {
        reject(error);
      } finally {
        setIsLoading(false)
      }
    })
  }

  const toggleAddonSelection = (value: string) => {
    setAddonSelected((prev) =>
      prev.includes(value)
        ? prev?.filter((item: any) => item !== value)
        : [...prev, value]
    );
  };

  function onSubmit(data: ServiceFormValues) {
    setIsLoading(true)

    const cleanedData = cleanFormData(data) as ServiceFormValues
    cleanedData['reskillify_brand_name'] =
      brands?.find(brand => brand.id == cleanedData['reskillify_brand_id'])
        ?.name ?? '';
    serviceAction.mutate(cleanedData)
    setIsLoading(false)
  }

  return {
    serviceTypes,
    isLoading,
    form,
    currencies,
    brands,
    selectedRecurring,
    addonSelected,
    toggleAddonSelection,
    setSelectedRecurring,
    setIsLoading,
    onSubmit,
    currentCurrency,
  }
}
