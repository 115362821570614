import {useMutation} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {CurrencySymbolPosition} from '@common/ito/composables/useCurrencies';
import {useNavigate} from 'react-router-dom';
import {UseFormReturn} from 'react-hook-form';
import routeNames from '@common/ito/route-names';

export interface PaymentGatewayFormValues {
  _method?: string;
  id?: number | string;
  name: string;
  title: string;
  mode: string;
  public_key: string;
  secret_key: string;
  client_id?: string;
  admin_email?: string;
  status?: number | boolean;
  billing_country: string;
  default_payment_gateway_id: number | string;
  payment_method_ids: number[] | string[];
  brand_id?: number | string;
  reskillify_brand_id?: string;
  reskillify_brand_name?: string;
  currency_id: number | string;
  currency_symbol_position: CurrencySymbolPosition | '';
  currency: object;
}

interface UpdatePaymentGatewayProps {
  form: UseFormReturn<any>;
  paymentGatewayId: number|string|undefined;
}

async function UpdatePaymentGateway(
  payload: PaymentGatewayFormValues,
  paymentGatewayId: string | number,
): Promise<Response> {
  payload._method = 'PUT';
  const response = await apiClient.post(`payment-gateways/${paymentGatewayId}`, payload);

  return response.data;
}

export default function useUpdatePaymentGateway({form, paymentGatewayId}: UpdatePaymentGatewayProps) {
  if (!paymentGatewayId) {
    throw new Error('paymentGatewayId is required in useUpdatePaymentGateway!')
  }

  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: PaymentGatewayFormValues) => UpdatePaymentGateway(payload, paymentGatewayId),
    onSuccess: () => {
      toast('Payment Gateway updated successfully');
      navigate(routeNames.payments.gateway.listing);
    },
    onError: (error) => {
      onFormQueryError(error, form);
    },
  })
}
