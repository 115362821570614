import {
  OrderSummary
} from '@common/auth/ui/ito/components/payments/orders/order-summary';
// import {
//   OrderListingHeader
// } from '@common/auth/ui/ito/components/payments/orders/listing-header';
import {
  OrderTable
} from '@common/auth/ui/ito/payments/orders/list/order-table';
import useOrder from '@common/ito/composables/useOrder';
import Pagination from '@common/auth/ui/ito/pagination';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import OrderPageHeader
  from '@common/auth/ui/ito/payments/orders/list/order-page-header';
import routeNames from '@common/ito/route-names';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {Trans} from '@common/i18n/trans';
import React from 'react';

export function ITO_OrderPage() {
  const breadcrumbs = [
    // TODO!
    // un comment the following line
    // {label: 'Payments', path: routeNames.payments.dashboard.listing},
    {label: 'Payment Gateway', path: routeNames.payments.gateway.listing},
    {label: 'Payments Activity', path: ''}
  ];
  const {
    orders,
    totals,
    currentPage,
    totalPages,
    isPendingOrderStatusTabs,
    isPending,
    setPage,
  } = useOrder(10);

  if (isPendingOrderStatusTabs) {
    return (
      <ProgressCircle
        className="my-80"
        size='sm'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          margin: '0',
        }}
        aria-label='Loading payment gateways...'
        isIndeterminate
      />
    )
  }

  return (
    <>
      <StaticPageTitle>
        <Trans message="Payment Activity" />
      </StaticPageTitle>
      <OrderPageHeader breadcrumbs={breadcrumbs} />
      <OrderSummary totals={totals}/>
      {/*<OrderListingHeader />*/}
      <br/>

      {isPending ? (
        <ProgressCircle
          className="my-80"
          size='sm'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0',
          }}
          aria-label='Loading payment gateways...'
          isIndeterminate
        />
      ) : (
        <>
          <OrderTable
            orders={orders}
          />
          {(orders?.length ?? 0) > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setPage}
            />
          )}
        </>
      )}
    </>
  );
}
