import {ITO_SocialLoginPanel} from '@common/auth/ui/ito/account-settings/social-login-panel/social-login-panel'
import {StaticPageTitle} from '@common/seo/static-page-title';
import {Trans} from '@common/i18n/trans';
import {
  ITO_BasicInfoPanel
} from '@common/auth/ui/ito/account-settings/basic-info-panel/basic-info-panel';
import {
  ITO_ChangePasswordPanel
} from '@common/auth/ui/ito/account-settings/change-password-panel/change-password-panel';
import {
  ITO_TwoFactorAuthenticationPanel
} from '@common/auth/ui/ito/account-settings/two-factor-authentication/two-factor-authentication';
import {
  ITO_ActiveSessionsPanel
} from '@common/auth/ui/ito/account-settings/active-sessions/active-sessions';
import {
  ITO_DatetimeAndLanguagePanel
} from '@common/auth/ui/ito/account-settings/datetime-and-language/datetime-and-language';
import {
  ITO_DangerzonePanel
} from '@common/auth/ui/ito/account-settings/dangerzone/dangerzone';
import {useContext, useMemo} from 'react';
import {SiteConfigContext} from '@common/core/settings/site-config-context';
import {useUser} from '@common/auth/ui/use-user';
import {ProgressCircle} from '@common/ui/progress/progress-circle';

export function ITO_AccountSettingsPage (){
  const {auth} = useContext(SiteConfigContext);
  const {data, isLoading} = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  const hasPassword = useMemo(() => data?.user?.has_password || false, [data]);

  return (
    <>
      <StaticPageTitle>
        <Trans message="Account Settings" />
      </StaticPageTitle>

      <div>
        <header className="mb-3 pb-4">
          <h1 className="mb-1 text-3xl font-semibold">
            <Trans message="Account Settings" />
          </h1>
          <p className="text-gray-500">
            <Trans message="View and update your account details, profile, and more." />
          </p>
        </header>

        {data?.user && auth.accountSettingsPanels?.map(panel => (
          <panel.component key={panel.id} user={data.user} />
        ))}

        {/*{isLoading || !data?.user || data.user ? (*/}
        {/*  <ProgressCircle*/}
        {/*    className="my-80"*/}
        {/*    aria-label="Loading user.."*/}
        {/*    isIndeterminate*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    <ITO_BasicInfoPanel user={data.user} />*/}
        {/*    <ITO_SocialLoginPanel user={data.user} />*/}
        {/*    <ITO_ChangePasswordPanel />*/}
        {/*    <ITO_TwoFactorAuthenticationPanel />*/}
        {/*    <ITO_ActiveSessionsPanel />*/}
        {/*    <ITO_DatetimeAndLanguagePanel />*/}
        {/*    <ITO_DangerzonePanel />*/}
        {/*  </>*/}
        {/*)}*/}

        {isLoading || !data?.user ? (
          <ProgressCircle
            className="my-80"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              margin: '0',
            }}
            aria-label="Loading user.."
            isIndeterminate
          />
          ) : (
          <>
            <ITO_BasicInfoPanel user={data.user} />
            {hasPassword && (
              <>
                {/*<ITO_SocialLoginPanel user={data.user} />*/}
                <ITO_ChangePasswordPanel />
              </>
            )}
            {/*<ITO_TwoFactorAuthenticationPanel user={data.user}  />*/}
            {/*<ITO_ActiveSessionsPanel />*/}
            {/*<ITO_DatetimeAndLanguagePanel />*/}
            <ITO_DangerzonePanel />
          </>
        )}
      </div>
    </>
  );
}
