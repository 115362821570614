import { UseMutationResult } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';
import useUpdatePaymentGateway, {
  PaymentGatewayFormValues
} from '@common/auth/ui/ito/payments/gateway/edit/update-payment-gateway';
import useCreatePaymentGateway
  from '@common/auth/ui/ito/payments/gateway/create/create-payment-gateway';

interface PaymentGatewayActionProps {
  form: UseFormReturn<any>;
  paymentGatewayId?: number | string;
}

export function usePaymentGatewayAction({
  form,
  paymentGatewayId,
}: PaymentGatewayActionProps): UseMutationResult<
  Response,
  Error,
  PaymentGatewayFormValues,
  unknown
> {
  if (paymentGatewayId) {
    return useUpdatePaymentGateway({ form, paymentGatewayId })
  }

  return useCreatePaymentGateway({ form })
}
