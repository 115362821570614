import Breadcrumb from '@common/auth/ui/ito/breadcrumb';
import Pagination from '@common/auth/ui/ito/pagination';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {usePaymentGateway} from '@common/ito/composables/usePaymentGateway';
import {PaymentGatewayTable} from '@common/auth/ui/ito/payments/gateway/listing/payment-gateway-table';
import {ITO_EmptyGatewayComponent} from '@common/auth/ui/ito/payments/gateway/listing/empty-gateways';
import routeNames from '@common/ito/route-names';
import {StaticPageTitle} from '@common/seo/static-page-title';
import React from 'react';

export function ITO_PaymentGatewayPage() {
  const breadcrumbItems = [
    {label: 'Payment Gateway', path: routeNames.payments.settings.listing},
  ];
  const {
    gateways,
    currentPage,
    totalPages,
    isLoading,
    error,
    isPending,
    isEnabled,
    setPage,
    deleteGateway,
  } = usePaymentGateway(10);

  const handleSort = (field: string) => {
    // Handle sorting logic here, e.g., update state to sort data
    console.log('need to implement sorting... where field ', field)
  };

  return (
      (gateways.length || isPending) ? (
        <>
          <StaticPageTitle>
            <Trans message="Payment Gateway" />
          </StaticPageTitle>

          <Breadcrumb items={breadcrumbItems}/>

          <header className="sm:mt-7 mb-6 flex flex-col lg:flex-row items-start lg:items-center space-y-3 lg:space-y-0 justify-between">
            <div>
              <h2 className="text-xl font-semibold text-black">
                <Trans message='Payment Gateway Overview' />
              </h2>
              <p className="text-gray-500 text-sm">
                <Trans message='See Our Payment Gateways' />
              </p>
            </div>
            <div className="flex flex-wrap w-full sm:w-auto space-x-2">
              {/*<Link to={routeNames.payments.gateway.choose} className="flex-grow sm:flex-grow-0 text-center border-purple-800 text-purple-800 py-1.5 hover:bg-purple-900 rounded-md border px-3 text-sm font-semibold hover:text-white">*/}
              {/*  <Trans message='+ Create Gateway' />*/}
              {/*</Link>*/}
              <Link to={routeNames.payments.gateway.choose} className="flex-grow sm:flex-grow-0 text-center bg-purple-800 hover:bg-purple-900 py-1.5 rounded-md px-3 text-sm font-semibold text-white">
                <Trans message='+ Add Gateway' />
              </Link>
            </div>
          </header>
          <main className="border-gray-200 max-w-full overflow-x-auto rounded-lg border">
            <PaymentGatewayTable
              gateways={gateways}
              isLoading={isLoading}
              error={error}
              onSort={handleSort}
              deleteGateway={deleteGateway}
              isEnabled={isEnabled}
            />
          </main>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setPage}
          />
        </>
      ): (
        <ITO_EmptyGatewayComponent />
      )
  );
}
