import {useMutation} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {useNavigate} from 'react-router-dom';
import {UseFormReturn} from 'react-hook-form';
import routeNames from '@common/ito/route-names';
import {
  PaymentGatewayFormValues
} from '@common/auth/ui/ito/payments/gateway/edit/update-payment-gateway';

interface CreatePaymentGatewayProps {
  form: UseFormReturn<any>;
}

async function CreatePaymentGateway(
  payload: PaymentGatewayFormValues,
): Promise<Response> {
  const response = await apiClient.post('payment-gateways', payload);
  return response.data;
}


export default function useCreatePaymentGateway({form}: CreatePaymentGatewayProps) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: PaymentGatewayFormValues) => CreatePaymentGateway(payload),
    onSuccess: () => {
      toast('Payment Gateway created successfully');
      navigate(routeNames.payments.gateway.listing);
    },
    onError: error => {
      onFormQueryError(error, form);
    },
  });
}
